.board-wrapper {
    height: 100%;
    overflow: hidden;
    /*margin: auto;*/
    max-width: 100vw;
}

.spacer {
    /*height: 100%;*/
}

.board-and-controls {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

header {
    border-top: 2px solid;
    text-align: center;
    margin-bottom: 16px;
}

input {
    background-color: inherit;
    color: inherit;
    border: unset;
    padding: 10px;
    border: 2px solid;
    min-width: 100px;
}

section {
    padding: 16px;
    visibility: hidden;
    display: none;
    max-width: 60ch;
    margin: auto;
}

section.visible {
    visibility: visible;
    display: flex;
    flex-direction: column;
}

button.selected {
    text-decoration: underline;
}

footer {
    text-align: center;
}

input {
    margin-bottom: 8px;
}

footer {
    padding-bottom: 8px;
    font-size: 0.8rem;
    opacity: 0.5;
}
