body {
  margin: 0;
  font-family: 'Roboto Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-color: black;
}

h1, h2, h3, h4, h5, h6 {
  text-align: center;
  text-transform: uppercase;
  font-family: 'Major Mono Display', monospace;
}

button {
  border: unset;
  background: inherit;
  color: inherit;
  font-family: inherit;
  text-transform: uppercase;
  padding: 8px 16px;
  margin: 0 8px;
  font-weight: 600;
  font-size: 0.9rem;
  cursor: pointer;
}

a {
  color: unset;
}
